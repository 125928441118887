/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LiquidityProtection,
  LiquidityProtectionInterface,
} from "../LiquidityProtection";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IBancorNetworkV3",
        name: "networkV3",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "vaultV3",
        type: "address",
      },
      {
        internalType: "contract ILiquidityProtectionSettings",
        name: "settings",
        type: "address",
      },
      {
        internalType: "contract ILiquidityProtectionStore",
        name: "store",
        type: "address",
      },
      {
        internalType: "contract ILiquidityProtectionStats",
        name: "stats",
        type: "address",
      },
      {
        internalType: "contract ILiquidityProtectionSystemStore",
        name: "systemStore",
        type: "address",
      },
      {
        internalType: "contract ITokenHolder",
        name: "wallet",
        type: "address",
      },
      {
        internalType: "contract ITokenGovernance",
        name: "networkTokenGovernance",
        type: "address",
      },
      {
        internalType: "contract ITokenGovernance",
        name: "govTokenGovernance",
        type: "address",
      },
      {
        internalType: "contract ICheckpointStore",
        name: "lastRemoveCheckpointStore",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "prevOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnerUpdate",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "acceptStoreOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "acceptWalletOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IConverterAnchor",
        name: "poolAnchor",
        type: "address",
      },
      {
        internalType: "contract IReserveToken",
        name: "reserveToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "addLiquidity",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "contract IConverterAnchor",
        name: "poolAnchor",
        type: "address",
      },
      {
        internalType: "contract IReserveToken",
        name: "reserveToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "addLiquidityFor",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "startIndex",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "endIndex",
        type: "uint256",
      },
    ],
    name: "claimBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IDSToken",
            name: "poolToken",
            type: "address",
          },
          {
            internalType: "contract IReserveToken",
            name: "reserveToken",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "positionIds",
            type: "uint256[]",
          },
        ],
        internalType: "struct LiquidityProtection.PositionList[]",
        name: "positionLists",
        type: "tuple[]",
      },
    ],
    name: "migratePositions",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IConverterAnchor[]",
        name: "poolAnchors",
        type: "address[]",
      },
    ],
    name: "migrateSystemPoolTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "newOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IConverterAnchor",
        name: "poolAnchor",
        type: "address",
      },
    ],
    name: "poolAvailableSpace",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IDSToken",
        name: "poolToken",
        type: "address",
      },
      {
        internalType: "contract IReserveToken",
        name: "reserveToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "reserveAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "poolRateN",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "poolRateD",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "reserveRateN",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "reserveRateD",
        type: "uint256",
      },
    ],
    name: "poolROI",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "portion",
        type: "uint32",
      },
    ],
    name: "removeLiquidity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "portion",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "removeTimestamp",
        type: "uint256",
      },
    ],
    name: "removeLiquidityReturn",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "settings",
    outputs: [
      {
        internalType: "contract ILiquidityProtectionSettings",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "stats",
    outputs: [
      {
        internalType: "contract ILiquidityProtectionStats",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "store",
    outputs: [
      {
        internalType: "contract ILiquidityProtectionStore",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "newProvider",
        type: "address",
      },
    ],
    name: "transferPosition",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "newProvider",
        type: "address",
      },
      {
        internalType: "contract ITransferPositionCallback",
        name: "callback",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "transferPositionAndNotify",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferStoreOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferWalletOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

export class LiquidityProtection__factory {
  static readonly abi = _abi;
  static createInterface(): LiquidityProtectionInterface {
    return new utils.Interface(_abi) as LiquidityProtectionInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LiquidityProtection {
    return new Contract(address, _abi, signerOrProvider) as LiquidityProtection;
  }
}
